import { createSlice } from '@reduxjs/toolkit';
import SelfGuard from 'selfguard-client';
let domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:8080';

const initialState = {
  group: null,
  sms_count: 0,
  email_count: 0,
  profiles: [],
  groups: [],
  descDate: true,
};

export const profileSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    appendProfile: (state,action) => {
      if(!state.profiles) state.profiles = [action.payload]
      else state.profiles.unshift(action.payload);
    },
    setCounts: (state, action) => {
      state.sms_count = action.payload.sms_count;
      state.email_count = action.payload.email_count;
    },
    setProfiles: (state,action) => {
      console.log(action.payload);
      if(action.payload.length === 0) {
        state.profiles = null;
      }
      else state.profiles = action.payload;
    },
    clearProfiles: (state, action) => {
      // Set all state values to their initial values
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    setGroups: (state,action) => {
      if(action.payload.length === 0) {
        state.groups = null;
      }
      else state.groups = action.payload;
    },
    setGroup: (state, action) => {
      state.profiles = [];
      state.group = action.payload;
    }
  },
});

export const {appendProfile, setProfiles, setGroups, setGroup, setCounts, clearProfiles} = profileSlice.actions;
export const selectProfiles = (state) => state.profiles.profiles;
export const selectGroups = (state) => state.profiles.groups;
export const selectGroup = (state) => state.profiles.group;
export const selectSMSCount = (state) => state.profiles.sms_count;
export const selectEmailCount = (state) => state.profiles.email_count;

export const getProfiles = (notification_group) => async (dispatch, getState) => {
  let sg = new SelfGuard(window.storage.api_key,null, null, null, domain);

  try {
    let data = await sg.getProfiles({limit:100,offset:0,notification_group});
    dispatch(setProfiles(data));
    return true
  }
  catch(err){
    console.log({err});
    dispatch(setProfiles([]));
    return false;
  }
};

export const getNotificationGroups = (searchParams, setSearchParams) => async (dispatch, getState) => {
  let sg = new SelfGuard(window.storage.api_key,null, null, null, domain);
  try {
    let data = await sg.getNotificationGroups();
    let group = searchParams.get('group');
    let the_group = data.filter(({notification_group})=> {return notification_group === group});
    if(the_group.length === 0) {
      group = data[0].notification_group
      the_group = [data[0]];
      setSearchParams({group,dashboard:searchParams.get('dashboard')});
    }
    dispatch(getProfiles(the_group[0].notification_group));
    dispatch(setGroup(the_group[0]))
    dispatch(setGroups(data));
    return data
  }
  catch(err){
    console.log({err});
    dispatch(setProfiles([]));
    return false;
  }
};

export const createNotificationGroup = ({contract_address, notification_group}) => async (dispatch, getState) => {
  let sg = new SelfGuard(window.storage.api_key,null, null, null, domain);
  try {
    await sg.createNotificationGroup({contract_address, notification_group});
    return true;
  }
  catch(err){
    console.log({err});
    throw new Error(err);
  }
};

export const deleteNotificationGroup = ({notification_group}) => async (dispatch, getState) => {
  let sg = new SelfGuard(window.storage.api_key,null, null, null, domain);
  try {
    await sg.deleteNotificationGroup({notification_group});
    return true;
  }
  catch(err){
    console.log({err});
    throw new Error(err);
  }
};

export const updateNotificationGroup = ({notification_group, old_notification_group, contract_address}) => async (dispatch, getState) => {
  let sg = new SelfGuard(window.storage.api_key,null, null, null, domain);
  try {
    console.log({notification_group, old_notification_group, contract_address});
    await sg.updateNotificationGroup({notification_group, old_notification_group, contract_address});
    return true;
  }
  catch(err){
    console.log({err});
    throw new Error(err);
  }
};

export const updateIntroductionMessage = ({id, email_subject, email_body, sms_text}) => async (dispatch, getState) => {
  let sg = new SelfGuard(window.storage.api_key,null, null, null, domain);
  try {
    await sg.updateIntroductionMessage({notification_group_id:id, email_subject, email_body, sms_text});
    return true;
  }
  catch(err){
    console.log({err});
    throw new Error(err);
  }
};

export const getNotificationCount = () => async (dispatch, getState) => {
  let sg = new SelfGuard(window.storage.api_key,null, null, null, domain);
  try {
    let data = await sg.getNotificationCount();
    dispatch(setCounts(data))
    return true;
  }
  catch(err){
    console.log({err});
    throw new Error(err);
  }
}

export default profileSlice.reducer;
