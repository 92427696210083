import { configureStore } from '@reduxjs/toolkit';
import keyPairReducer from './key_pair.js';
import eventReducer from './event.js';
import profileReducer from './profile.js';
import domainReducer from './domain.js';
import usageLimitsReducer from './usage_limits.js';
import fileReducer from './files.js';
import authReducer from './auth.js';
import sharedFileReducer from './shared_files.js';

export const store = configureStore({
  reducer: {
    auth: authReducer,

    files: fileReducer,
    shared_files: sharedFileReducer,

    domains: domainReducer,
    usage_limits: usageLimitsReducer,
    key_pairs:keyPairReducer,
    events: eventReducer,

    profiles: profileReducer
  },
});
