import React from "react";

const Integrations = () => {
  return (
    <div className="w-full flex flex-col items-center mt-48">
      <h3 className="text-guard">SelfGuard Products</h3>
      <div className="w-full ">
        <div className="w-full flex lg:flex-row flex-col lg:space-x-8 space-y-8 mt-8">
          <a
            href="https://getnotified.xyz/"
            target="_blank"
            rel="noreferrer"
            className="border border-black/10 w-full h-40 flex flex-col p-4 rounded-2xl"
            style={{marginTop: `calc(2rem)`}}
          >
            <p className="text-self text-xl"><b>Get Notified</b></p>
            <div style={{marginTop:'20px'}}>
              <img src="/speaker.svg" className="w-8" alt="" />
              <p className="text-self text-l mt-4">Send Encrypted Notifications</p> 
            </div>
          </a>

          <a
            href="https://filepeer.xyz/"
            target="_blank"
            rel="noreferrer"
            className="bg-guard w-full h-40 flex flex-col p-4 rounded-2xl"
          > 
            <p className="text-white text-xl"><b>File Peer</b></p>
            <div style={{marginTop:'20px'}}>
              <img src="/storage.svg" className="w-8" alt="" />
              <p className="text-white text-l mt-4">Encrypted File Storage</p>
            </div>
          </a>
          <a
            href="https://docs.selfguard.xyz/introduction"
            target="_blank"
            rel="noreferrer"
            className="bg-self w-full h-40 flex flex-col p-4 rounded-2xl"
          >
            <p className="text-white text-xl"><b>SelfGuard Core</b></p>
            <div style={{marginTop:'20px'}}>
              <img src="/lock.svg" className="w-8" alt="" />
              <p className="text-white text-l mt-4">Encryption API</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
