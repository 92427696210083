import React, { Fragment } from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";

const code = `//Installation
import SelfGuard from 'selfguard-client'
import { Notifications } from 'selfguard-react-components';

let sg = new SelfGuard('API_KEY')

// or if a key pair has been generated and you wish to asymmetrically encrypt all encryption keys.
// this ensures that not even SelfGuard can decrypt any encrypted data
let sg = new SelfGuard('API_KEY',PUB_KEY, PRIV_KEY)

/* ENCRYPTION FUNCTIONS */

// Encrypt
let { ciphertext, encryption_key_id } = await sg.encrypt('This is a secret');

// Decrypt
await sg.decrypt(ciphertext, encryption_key_id);

// Encrypt With Password
let ciphertext = sg.encryptWithPassword('value4','password1');

// Decrypt With Password
sg.decryptWithPassword(ciphertext,'password1');

/* FILE STORAGE FUNCTIONS */

// Upload/Encrypt File

await sg.encryptFile(file,(err,progress) => {
	console.log(progress);
});

// Decrypt File

await sg.decryptFile(id,(err,progress) => {
	console.log(progress);
});

// Get List of Files

await sg.getFiles();

/* TOKENIZATION FUNCTIONS */

// Tokenize
let token_id = await sg.tokenize('This is some super top secret text!')

// Detokenize
await sg.detokenize(token_id)

/* KEY-VALUE FUNCTIONS */

// Key-Value Put
await sg.put('key','this is a value');

// Key-Value Get
await sg.get('key');

// Get All Keys
await sg.getKeys();

/* ARRAY FUNCTIONS */

// Array Initialization
let key = 'key';
await sg.initArray(key);

// Add to Array
await sg.addToArray(key,'value');
await sg.addToArray(key,'value2');

// Get Array
await sg.getArray(key);

// Add User to Array (allows a user to access the contents of the array)
await sg.addUserToArray(key, user_public_key);

// Get List of Arrays

await sg.getArrayNames();

/* KEY PAIR FUNCTIONS */

// Create Key Pair
let key_pair = sg.createKeyPair('rsa'); //Generate Key Pair

// Upload Key Pair (encrypts the private key with a password)
await sg.uploadKeyPair(rsa,'password');

// Get Key Pairs
await sg.getKeyPairs();

/* NOTIFICATIONS FUNCTIONS
Used to send texts or emails to addresses who's email and phone number
are stored using the encrypted key/value storage.
*/

//React Component for Notificaitions
npm install selfguard-react-components

import { Notifications } from  'selfguard-react-components';
<Notifications userAddress={'0xadfb...'} api_key={'API_KEY'} />

//Upload Profile
await sg.updateProfile('0xadfb..',{email:'test@gmail.com',phone:'+12121112345'});


// Send SMS
await sg.sendSMS({address:'0xadfb...',text:'Example Text'});

// Send Email
await sg.sendEmail({address:'0xadfb...',from:'example@test.com',fromName:'test',replyTo:'reply@test.com', reployToName:'test',subject:'Test Subject', html:'This is the content of the email'});`;

const Files = () => {
  return (
    <div className="w-full mt-48 flex justify-center flex-col items-center">
      <div className="flex flex-col lg:flex-row items-center lg:items-start w-full items-center">
        <div className="lg:w-1/2 w-full flex flex-col lg:pr-24">
          <h2 className="text-self">SelfGuard Core</h2>
          <h3 className="text-guard font-medium mt-4" style={{lineHeight:'60px'}}>
            Encrypt anything and everything
          </h3>
          <div className="flex mt-4">
          <a
            href="https://docs.selfguard.xyz/introduction"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex">
              <p className="text-self mr-2">View Docs</p>
              <img src="/arrow.svg" alt="" className="w-4" />
            </div>
          </a>
        </div>
        <div className="flex mt-8 space-y-2 flex-col mb-8">
          <p className="uppercase tracking-widest text-sm text-guard/50">
            Features
          </p>
          <div className="w-full h-px bg-pale" />
          <p className="text-guard text-2xl">Raw Encryption</p>
          <div className="w-full h-px bg-pale" />
          <p className="text-guard text-2xl">Data Tokenization</p>
          <div className="w-full h-px bg-pale" />
          <p className="text-guard text-2xl">Encrypted Key Value Storage</p>
          <div className="w-full h-px bg-pale" />
          <p className="text-guard text-2xl">                                                                 </p>
          <div className="w-full h-px bg-pale" />
        </div>
        </div>
       <div className="lg:w-1/2 font-code bg-[#011627] p-8 h-[24rem] w-full overflow-auto">
        <Highlight {...defaultProps} theme={theme} code={code} language="jsx">
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <Fragment>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </Fragment>
          )}
        </Highlight>
      </div>
      </div>
    </div>
  );
};

export default Files;
