
import moment from 'moment';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {deleteDomain} from '../../../../redux/domain';
import ClipLoader from "react-spinners/ClipLoader";

export default function Domains({data}) {
  let dispatch = useDispatch();
  let {created_at, domain } = data;
  let [loading, setLoading] = useState(false);

  let deleteD = async () => {
    setLoading(true);
    await dispatch(deleteDomain(domain));
    setLoading(false);
  }

  let showDate = () => {
    //if created at was within the last day just show the time with am/pm, else show the date and time
    if(moment(created_at).isSame(moment(),'day')){
      return moment(created_at).format('hh:mm A')
    }
    else {
      return moment(created_at).format('MM/DD/YY')
    }
  }

  return (
    <li>
        <div className="px-4 py-4 sm:px-6">
          <div className="flex items-center justify-between">
            <div className="truncate text-sm font-medium text-blue-600">{domain}</div>
            {loading ? 
              <ClipLoader />
            :
            <button
              onClick={deleteD}
              type="button"
              className="rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Remove
            </button>
            }
            
          </div>
          <div className="mt-2 flex justify-between">
            <div className="sm:flex">
              <div className="flex items-center text-sm text-gray-500">
                {showDate()}
              </div>
            </div>
          </div>
        </div>
    </li>
  )
}

