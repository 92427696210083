import { createSlice } from '@reduxjs/toolkit';
import SelfGuard from 'selfguard-client';

const initialState = {
  events: [],
  descDate: true,
};

export const docSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    sortDate: (state) => {
      if(state.descDate){
        state.events.sort((a,b)=>{return new Date(a.created_at) - new Date(b.created_at)});
        state.descDate = false;
      }
      else {
        state.events.sort((a,b)=>{return new Date(b.created_at) - new Date(a.created_at)});
        state.descDate = true
      }
    },
    appendEvent: (state,action) => {
      if(!state.events) state.events = [action.payload]
      else state.events.unshift(action.payload);
    },
    clearEvents: (state, action) => {
      // Set all state values to their initial values
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    setEvents: (state,action) => {
      if(action.payload.length === 0) {
        state.events = null;
      }
      else state.events = action.payload;
    },
  },
});

export const {setEvents, sortDate, clearEvents} = docSlice.actions;
export const selectEvents = (state) => state.events.events;
let domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:8080';

export const getEvents = () => async (dispatch, getState) => {
  try {
    let sg = new SelfGuard(window.storage.api_key, null, null, null, domain);
    let data = await sg.retrieveEvents();
    dispatch(setEvents(data));
    return true
  }
  catch(err){
    console.log(err);
    throw new Error(err);
  }
};


export default docSlice.reducer;
