import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SelfGuardCore from "./SelfGuardCore";
import FilePeer from "./FilePeer";
import GetNotified from "./GetNotified";
import Header from './header';

export default function Home() {
  let [searchParams, setSearchParams] = useSearchParams();
  let curr = searchParams.get("dashboard");

  useEffect(() => {
    if (!curr) {
      setSearchParams({
        dashboard: "selfguard_core",
        group: searchParams.get("group"),
      });
    }
  }, [curr, setSearchParams, searchParams]);

  const messages = {
    selfguard_core: 'SelfGuard Core',
    file_peer: 'File Peer',
    get_notified: 'Get Notified',
  };

  return (
    <div className="min-h-full bg-slate-100">
      <Header />
      <div className="py-10">
        <header>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              {messages[curr]}
            </h1>
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            {/* Replace with your content */}
            <div className="px-4 py-8 sm:px-0">
              {/* <div className="h-96 rounded-lg border-4 border-dashed border-gray-200" /> */}
              <div className="w-full flex flex-col">
                {curr === "selfguard_core" && <SelfGuardCore />}
                {curr === "file_peer" && <FilePeer />}
                {curr === "get_notified" && <GetNotified />}
              </div>
            </div>
            {/* /End replace */}
          </div>
        </main>
      </div>
    </div>
  );
}

