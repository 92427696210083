/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import {clearLimits} from '../../redux/usage_limits';
import {clearProfiles} from '../../redux/profile';
import {clearKeys} from '../../redux/key_pair';
import {clearDomains} from '../../redux/domain';
import {clearEvents} from '../../redux/event';
import {useDispatch } from 'react-redux';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  let curr = searchParams.get("dashboard");

  async function logoutClicked() {
    window.storage.removeItem('api_key');
    window.storage.removeItem('key_pair_type');
    window.storage.removeItem('public_key');
    window.storage.removeItem('private_key');
    window.storage.removeItem('user');
    dispatch(clearLimits());
    dispatch(clearProfiles());
    dispatch(clearKeys());
    dispatch(clearDomains());
    dispatch(clearEvents());
    await signOut();
    window.location.href = '/';
  }

  useEffect(() => {
    if (!curr) {
      setSearchParams({
        dashboard: "selfguard_core",
        group: searchParams.get("group"),
      });
    }
  }, [curr, setSearchParams, searchParams]);

  let active = "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900";
  let inactive = "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700";

  let activeMobile = "block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700";
  let inactiveMobile = "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800";

  return (
    <Disclosure as="nav" className="bg-white shadow-sm">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src="/logo2.png"
                    alt="SelfGuard"
                  />
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src="/logo2.png"
                    alt="SelfGuard"
                  />
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <button
                    className={curr === 'selfguard_core' ? active : inactive}
                    onClick={() => {
                      setSearchParams({ dashboard: "selfguard_core" });
                    }}
                  >
                    SelfGuard Core
                  </button>
                  <button
                    className={curr === 'file_peer' ? active : inactive}
                    onClick={() => {
                      setSearchParams({ dashboard: "file_peer" });
                    }}
                  >
                    File Peer
                  </button>
                  <button
                    className={curr === 'get_notified' ? active : inactive}
                    onClick={() => {
                      setSearchParams({ dashboard: "get_notified" });
                    }}
                  >
                    Get Notified
                  </button>
                </div>
              </div>
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-4 flex-shrink-0">
                  <div>
                    <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 ">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                          {({ active }) => (
                            <p className='block px-4 py-2 text-sm text-gray-700'>
                              {window.storage.user}
                            </p>
                          )}
                        </Menu.Item>
                      <hr/>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full text-left')}
                            onClick={logoutClicked}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 pt-2 pb-3">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
              <Disclosure.Button
                as="a"
                href="#"
                onClick={() => {
                  setSearchParams({ dashboard: "selfguard_core" });
                }}
                className={curr === 'selfguard_core' ? activeMobile : inactiveMobile}
              >
                SelfGuard Core
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className={curr === 'file_peer' ? activeMobile : inactiveMobile}
                onClick={() => {
                  setSearchParams({ dashboard: "file_peer" });
                }}
              >
                File Peer
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className={curr === 'get_notified' ? activeMobile : inactiveMobile}
                onClick={() => {
                  setSearchParams({ dashboard: "get_notified" });
                }}
              >
                Get Notified
              </Disclosure.Button>
            </div>
            <div className="border-t border-gray-200 pb-3">
              <div className="mt-2">
                <Disclosure.Button
                  style={{cursor:'auto'}}
                  as="a"
                  href="#"
                  className="block px-4 py-2 text-base font-medium text-gray-500"
                >
                  {window.storage.user}
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  onClick={logoutClicked}
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
