import React from "react";

const Files = () => {
  return (
    <div className="w-full mt-48 flex justify-center flex-col items-center">
      <div className="flex flex-col lg:flex-row items-center lg:items-start w-full items-center">
        <div className="lg:w-1/2 flex flex-col lg:pr-24 w-full">
          <h2 className="text-self">Get Notified</h2>
          <h3 className="text-guard font-medium mt-4" style={{lineHeight:'60px'}}>
            Send Encrypted Notifications
          </h3>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 mt-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
          </svg>

          <p className="text-guard text-2xl mt-4">Collect User Contact Data</p>
          <p className="text-guard/50 leading-relaxed mt-4">
            Embed a widget into your site or use an automated link to collect users contact data. All data will remain encrypted.
          </p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 mt-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
          </svg>

          <p className="text-guard text-2xl mt-4">Send Email & Text Notifications</p>
          <p className="text-guard/50 leading-relaxed mt-4">
            Send notifications to your users' wallet addresses or in bulk. Notifications will automatically get sent to the users email and/or phone.
          </p>
        </div>
        <div className="lg:w-1/2 mt-12 lg:mt-0 flex flex-col w-full bg-pale flex items-center">
          <img
            src="/notify.svg"
            alt="alt"
            style={{height:'100%'}}
          />
          <div className="flex p-8 w-full bg-self">
            <a href="https://getnotified.xyz/" target="_blank" rel="noreferrer" className="w-full">
              <div className="flex">
                <p className="text-white mr-4">Visit Get Notified</p>
                <img src="/white-arrow.svg" alt="" className="w-4" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Files;
