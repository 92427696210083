import { TbMenu } from "react-icons/tb";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

const Navbar = () => {

  return (
    <div className="w-full flex py-8 justify-between items-center">
      <a href="https://selfguard.xyz/">
        <img src="/logo.svg" alt="" style={{width:'200px'}} />
      </a>
      <div className="items-center hidden md:flex space-x-8">
        <a href="https://getnotified.xyz/" target="_blank" rel="noreferrer">
          <p className="text-guard/50 hover:text-guard transition ease-linear">
            Get Notified
          </p>
        </a>
        <a href="https://filepeer.xyz/" target="_blank" rel="noreferrer">
          <p className="text-guard/50 hover:text-guard transition ease-linear">
            File Peer
          </p>
        </a>
        <a
          href="https://docs.selfguard.xyz/introduction"
          target="_blank"
          rel="noreferrer"
        >
          <p className="text-guard/50 hover:text-guard transition ease-linear">
            SG Core Docs
          </p>
        </a>
        <a
          href="https://selfguard.xyz/pricing/"
          target="_blank"
          rel="noreferrer"
        >
          <p className="text-guard/50 hover:text-guard transition ease-linear">
            Pricing
          </p>
        </a>
        <a
          href={`/auth?redirectToPath=/home&show=signin`}
        >
          <p className="text-guard/50 hover:text-guard transition ease-linear">
            Login
          </p>
        </a>
        <a 
          href="mailto:arjun@selfguard.xyz"
          target="_blank"
          rel="noreferrer"
        >
          <p className="text-guard/50 hover:text-guard transition ease-linear" >Contact</p>
        </a>
      </div>
      <div className="md:hidden">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <TbMenu
              className="cursor-pointer text-2xl text-guard"
            />
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal className="">
            <DropdownMenu.Content
              align="end"
              sideOffset={5}
              className="rounded-xl max-w-xl md:hidden"
            >
              <div className="w-96 flex flex-col space-y-4 h-full rounded-xl p-8 bg-white shadow-nav">
                <a
                  href="https://getnotified.xyz/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-guard/50">Get Notified</p>
                </a>
                <a
                  href="https://filepeer.xyz/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-guard/50">File Peer</p>
                </a>
                <a
                  href="https://docs.selfguard.xyz/introduction"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-guard/50">SG Core Docs</p>
                </a>
                <a
                  href="https://selfguard.xyz/pricing/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-guard/50">Pricing</p>
                </a>
                <a
                  href={`/auth?redirectToPath=/home&show=signin`}
                >
                  <p className="text-guard/50">
                    Login
                  </p>
                </a>
                
                <a 
                  href="mailto:arjun@selfguard.xyz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-guard/50">Contact</p>
                </a>
              </div>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};

export default Navbar;
