import Domain from './domain';
import {useSelector} from 'react-redux';
import {selectDomains} from '../../../../redux/domain';
import Generate from './generateDomain';

export default function Domains() {
  let domains = useSelector(selectDomains);
  return (
        <div className="bg-white col-span-2 xl:col-span-1 row-span-2 rounded-sm sm:rounded-lg sm:shadow" style={{height:'370px'}}>
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">API Domain Whitelist</h3>
                <p className="mt-1 text-sm text-gray-500">List of domains that have been whitelisted to use your api key</p>
              </div>
              <div className="ml-4 mt-4 flex-shrink-0">
                <Generate/>
              </div>
            </div>
          </div>
          {/*if domains are length of zero, indicate that it is loading*/}
          {domains && domains.length === 0 &&
          <div className="flex items-center" style={{height:'250px',justifyContent:'center'}}>
            <button
              id='nodomains'
              type="button"
              className="
              my-auto block m-4 rounded-lg  p-12 text-center
              "
            >
              <span className="mt-2 block text-sm font-medium text-gray-900">Loading Domains...</span>
            </button>
          </div>
          }
          {domains ? 
          <ul className="divide-y divide-gray-200" style={{height:'250px',overflowY:'auto'}}>
            {domains.map((domain,i)=>(
              <Domain key={i} data={domain}/>
            ))}
          </ul>
          :
          <div className="flex items-center" style={{height:'250px',justifyContent:'center'}}>
            <button
              id='nodomains'
              type="button"
              className="
              my-auto block m-4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center
              "
            >
              <span className="mt-2 block text-sm font-medium text-gray-900">No Domains Added Yet</span>
            </button>
          </div>
          }
        </div>
  )
}

