import React, { useState } from "react";
import $ from 'jquery';
import SelfGuard from 'selfguard-client';
import Toastify from 'toastify-js'
import Modal from '../../../../helper/modal.js';
import {selectGroup, getNotificationCount, getProfiles,selectSMSCount} from '../../../../../redux/profile';
import {selectLimits} from '../../../../../redux/usage_limits';

import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import { useSearchParams } from "react-router-dom";

let domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080"

export default function Home() {
  let group = useSelector(selectGroup);
  const [text, setText] = useState('');
  let [loading, setLoading] = useState(false);
  let dispatch = useDispatch();
  let [searchParams,] = useSearchParams();
  const [open, setOpen] = useState(false);

  let sms_count = useSelector(selectSMSCount);
  let usageLimits = useSelector(selectLimits);

  async function sendSMS(e){
    if(loading) return;
    try {
      setLoading(true);
      if(e && e.preventDefault) e.preventDefault();
      let sg = new SelfGuard(window.storage.api_key, null, null, null, domain);
      await sg.sendBulkSMS({notification_group:group.notification_group,text});
      Toastify({text:"Bulk SMS Sent",style: {background: "linear-gradient(to right, #198754, #198751"}}).showToast();
      $('#closeModalSMSBulk').click();
      setText('');
      dispatch(getNotificationCount());
      dispatch(getProfiles(searchParams.get('group')))
    }
    catch(err){
      console.log(err);
    }
    setOpen(false);
    setLoading(false);
  }

  let showModal = () => {
    setOpen(true);
  }

  let fields = [{onChange:setText,type:'text',value:text,label:'Text',disabled:false}];

  let background = usageLimits.sms_notifications < sms_count ? 'bg-slate-600' : 'bg-blue-600 hover:bg-blue-700';

  return (
    <>
    <button onClick={showModal} disabled={usageLimits.sms_notifications < sms_count} type="button" className={`mt-4 mr-4 relative inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm ${background}`}>Send Bulk Text</button>
    <Modal  open={open} setOpen={setOpen} title={'Send Bulk SMS'} loading={loading} id='sendSMSBulk' closeId={'closeModalSMSBulk'} fields={fields} onSubmit={sendSMS}/>
    </>
    )
}

