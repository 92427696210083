import React, { useState } from "react";
import $ from 'jquery';
import {useDispatch} from 'react-redux';
import Toastify from 'toastify-js'
import Modal from '../../../../helper/modal.js';
import {selectGroup,updateIntroductionMessage} from '../../../../../redux/profile';
import {useSelector} from 'react-redux';

export default function Home() {
  let group = useSelector(selectGroup);
  let dispatch = useDispatch();
  const [text, setText] = useState(group.introduction_message.sms_text);
  const [html, setHTML] = useState(group.introduction_message.email_body);
  const [subject, setSubject] = useState(group.introduction_message.email_subject);
  let [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  async function sendEmail(e){
    if(loading) return;
    try {
      if(e && e.preventDefault) e.preventDefault();
      setLoading(true);
      await dispatch(updateIntroductionMessage({id:group.id, email_subject:subject, email_body:html,sms_text:text}));
      Toastify({text:"Introduction Mesage Set",style: {background: "linear-gradient(to right, #198754, #198751"}}).showToast();
      $('#closeIntro').click();
    }
    catch(err){
      console.log(err);
    }
    setLoading(false);
    setOpen(false);
  }

  let fields = [
    {onChange:setText,type:'text',value:text,label:'SMS Text',disabled:false},
    {onChange:setSubject,type:'text',value:subject,label:'Email Subject',disabled:false},
    {onChange:setHTML,type:'text',value:html,label:'Email Body',disabled:false,textarea:true}
  ];

  return (
    <>
      <button onClick={() => setOpen(true)} type="button" className="mt-4 mr-2 relative inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700">Set Introduction Message</button>
      <Modal open={open} setOpen={setOpen} title={'Set Introduction Message'} loading={loading} id='sendIntro' closeId={'closeIntro'} fields={fields} onSubmit={sendEmail}/>
    </>
  )
}
