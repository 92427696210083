import Event from './event';
import {useSelector} from 'react-redux';
import {selectEvents} from '../../../../redux/event';

export default function Activity() {
  let events = useSelector(selectEvents);

  return (
        <div className="bg-white col-span-2 xl:col-span-1 row-span-2 rounded-sm sm:rounded-lg sm:shadow" style={{height:'370px'}}>
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">API Activity</h3>
                <p className="mt-1 text-sm text-gray-500">List of events pertaining to the usage of your api key</p>
              </div>
            </div>
          </div>

          {events && events.length === 0 &&
          <div className="flex items-center" style={{height:'250px',justifyContent:'center'}}>
            <button
              id='nodomains'
              type="button"
              className="
              my-auto block m-4 rounded-lg  p-12 text-center
              "
            >
              <span className="mt-2 block text-sm font-medium text-gray-900">Loading Events...</span>
            </button>
          </div>
          }

          {events ? 
          <ul className="divide-y divide-gray-200" style={{height:'250px',overflowY:'auto'}}>
            {events.map((event, index) => (
              <Event key={index} event={event}/>
            ))}
          </ul>
          :
          <div className="flex items-center" style={{height:'250px',justifyContent:'center'}}>
            <button
              id='nodomains'
              type="button"
              className="
              my-auto block m-4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center
              "
            >
              <span className="mt-2 block text-sm font-medium text-gray-900">No Events</span>
            </button>
          </div>
          }
        </div>
  )
}

