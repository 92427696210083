import React from "react";
import Activity from './Activity';
// import KeyPairs from './KeyPairs';
import Domains from './Domains';
import APIKey from './API_Key';
// import Encryptions from './Encryptions';
// import DataTokenizations from './Data_Tokenizations';
// import ComingSoon from './Coming_Soon';

import {useEffect, useState} from 'react'
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import {useDispatch} from 'react-redux';
import {getEvents} from '../../../redux/event';
import {getKeyPairs} from '../../../redux/key_pair';
import {getDomains} from '../../../redux/domain';

const APIDashboard = () => {
  let session = useSessionContext();
  let [called,setCalled] = useState(false);
  let dispatch = useDispatch();

  console.log({session});

  useEffect(()=>{
    console.log({called,session});
    async function fetchData() {
      if(session && session.doesSessionExist && !called){
        try {
          setCalled(true);
          await dispatch(getEvents());
          await dispatch(getKeyPairs());
          await dispatch(getDomains());
        }
        catch(err){
          console.log({err});
        }
      }
    }
    fetchData();
  },[called, session, dispatch])

  return (
    <>
      <APIKey/>
      <div className="grid mt-4 gap-4 grid-cols-2">
        <Domains/>
        <Activity/>
      </div>
      <div className="relative mt-8 mb-8">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
      {/*div where the elements are centered inside */}
      <div className="flex justify-center mt-8">
      <a
        type="button"
        href='https://docs.selfguard.xyz'
        style={{justifyContent:'center',width:'200px'}}
        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        View Docs
      </a>
      </div>
      
    </>
  );
};

export default APIDashboard;
