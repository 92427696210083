import React,{useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getNotificationGroups, getNotificationCount, selectGroup } from "../../../redux/profile";
import { getUsageLimits} from '../../../redux/usage_limits';
import Groups from './Groups';
import Profiles from './Profiles';


const NotificationsDashboard = () => {
  let [called,setCalled] = useState(false);
  let dispatch = useDispatch();
  let [searchParams,setSearchParams] = useSearchParams();
  let group = useSelector(selectGroup);


  useEffect(()=>{
    async function fetchData() {
      if(!called){
        try {
          dispatch(getNotificationCount());
          dispatch(getUsageLimits());
          await dispatch(getNotificationGroups(searchParams,setSearchParams));
          setCalled(true);
        }
        catch(err){
          setCalled(true);
        }
      }
    }
    fetchData();
  },[called, dispatch, searchParams, setSearchParams])

  return (
    <>
      <div className="grid h-full gap-8 grids-cols-1 w-full">
        <Groups/>
        {group && <Profiles/>}
      </div>
    </>
  );
};

export default NotificationsDashboard;
