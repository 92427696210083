import SuperTokens, {
  SuperTokensWrapper,
  getSuperTokensRoutesForReactRouterDom,
} from "supertokens-auth-react";
import Home from "./components/Home";
import ConfirmLoggedIn from "./components/Home/CheckLoggedIn.js";
import Landing from "./components/Landing";
import Footer from "./components/Landing/footer.js";

// import Navbar from './components/Navbar';
import {
  Routes,
  Navigate,
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import {
  getApiDomain,
  getWebsiteDomain,
  SuperTokensOptions,
} from "./components/SuperTokens/index.js";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import { signIn, signUp } from "./auth";
import Pricing from "./components/Pricing";

SuperTokens.init({
  appInfo: {
    appName: "SuperTokens Demo App", // TODO: Your app name
    apiDomain: getApiDomain(), // TODO: Change to your app's API domain
    websiteDomain: getWebsiteDomain(), // TODO: Change to your app's website domain
  },
  recipeList: [
    EmailPassword.init(SuperTokensOptions(signIn, signUp)),
    Session.init(),
  ],
});

function App() {
  // const dispatch = useDispatch();
  return (
    <SuperTokensWrapper>
      <div className="App">
        <Router>
          <div className="fill">
            <Routes>
              {getSuperTokensRoutesForReactRouterDom(
                require("react-router-dom")
              )}
              <Route
                path="/"
                element={
                  <>
                    <Landing />
                  </>
                }
              />
              <Route
                path="/home"
                element={
                  <ConfirmLoggedIn>
                    <div style={{ minHeight: "calc(100vh - 80px)" }}>
                      <Home />
                      <Footer/>
                    </div>
                  </ConfirmLoggedIn>
                }
              />
              <Route
                path="/pricing"
                element={
                  <>
                    <Pricing />
                    <Footer/>
                  </>
                }
              />
              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          </div>
        </Router>
      </div>
    </SuperTokensWrapper>
  );
}

export default App;
