import moment from 'moment';

export default function Domains({event}) {
  let {created_at, origin, type, external_id } = event;

  if(external_id && external_id.length > 36) external_id = external_id.substring(36);

  let showDate = () => {
    //if created at was within the last day just show the time with am/pm, else show the date and time
    if(moment(created_at).isSame(moment(),'day')){
      return moment(created_at).format('hh:mm A')
    }
    else {
      return moment(created_at).format('MM/DD/YY')
    }
  }

  return (
    <li>
        <div className="px-4 py-4 sm:px-6">
          <div className="flex items-center justify-between">
            <div className="truncate text-sm font-medium text-blue-600">{type}</div>
            <div className="truncate text-sm text-grey-300">{origin}</div>
          </div>
          <div className="mt-2 flex justify-between">
            <div className="sm:flex">
              <div className="flex items-center text-sm text-gray-500">
                {showDate()}
              </div>
            </div>
          </div>
        </div>
    </li>
  )
}

