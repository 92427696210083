import React, { useState } from "react";
import $ from 'jquery';
import SelfGuard from 'selfguard-client';
import Toastify from 'toastify-js'
import Modal from '../../../../helper/modal.js';
import {selectGroup, getNotificationCount, getProfiles, selectEmailCount} from '../../../../../redux/profile';
import {selectLimits} from '../../../../../redux/usage_limits';

import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import { useSearchParams } from "react-router-dom";

let domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080"

export default function Home() {
  let group = useSelector(selectGroup);
  const [text, setText] = useState('');
  const [subject, setSubject] = useState('');
  let [loading, setLoading] = useState(false);
  let dispatch = useDispatch();
  let [searchParams,] = useSearchParams();
  const [open, setOpen] = useState(false);

  let email_count = useSelector(selectEmailCount);
  let usageLimits = useSelector(selectLimits);

  async function sendEmail(e){
    if(loading) return;
    try {
      if(e && e.preventDefault) e.preventDefault();
      setLoading(true);
      let sg = new SelfGuard(window.storage.api_key, null, null, null, domain);
      await sg.sendBulkEmail({notification_group:group.notification_group, subject, body:text});
      Toastify({text:"Bulk Email Sent",style: {background: "linear-gradient(to right, #198754, #198751"}}).showToast();
      $('#closeModalEmailBulk').click();
      setText('');
      setSubject('');
      dispatch(getNotificationCount());
      dispatch(getProfiles(searchParams.get('group')))
    }
    catch(err){
      console.log(err);
    }
    setLoading(false);
    setOpen(false);
  }


  let showModal = () => {
    setOpen(true);
  }

  let fields = [
    {onChange:setSubject,type:'text',value:subject,label:'Subject',disabled:false},
    {onChange:setText,type:'text',value:text,label:'Body',disabled:false,textarea:true}
  ];

  let background = usageLimits.email_notifications < email_count ? 'bg-slate-600' : 'bg-blue-600 hover:bg-blue-700';

  return (
    <>
      <button onClick={showModal} disabled={usageLimits.email_notifications < email_count} type="button" className={`mt-4 mr-2 lg:mt-0 relative inline-flex items-center rounded-md border border-transparent ${background} px-4 py-2 text-sm font-medium text-white shadow-sm`}>Send Bulk Email</button>
      <Modal open={open} setOpen={setOpen} title={'Send Bulk Email'} loading={loading} id='sendEmailBulk' closeId={'closeModalEmailBulk'} fields={fields} onSubmit={sendEmail}/>
    </>
  )
}

