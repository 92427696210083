import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
Session.addAxiosInterceptors(axios);

export function getApiDomain() {
  const apiPort = process.env.REACT_APP_API_PORT || 8080;
  const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
  return apiUrl;
}

export default async function getJWT() {
  let response = await axios.get(getApiDomain() + "/getJWT");
  let jwt = response.data.token;
  return jwt;
}

export async function isLoggedIn() {
  axios.defaults.headers.common = {
    "X-API-Key": window.storage.api_key
  };
  let response = await axios.get(getApiDomain() + "/verifyAPISession");
  return response.data;
}