import { createSlice } from '@reduxjs/toolkit';
import SelfGuard from 'selfguard-client';
import {encryptWithPassword} from 'selfguard-client/modules/encryption.js';

let domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:8080';

const initialState = {
  key_pairs: [],
  descDate: true,
};

export const docSlice = createSlice({
  name: 'key_pairs',
  initialState,
  reducers: {
    sortDate: (state) => {
      if(state.descDate){
        state.key_pairs.sort((a,b)=>{return new Date(a.created_at) - new Date(b.created_at)});
        state.descDate = false;
      }
      else {
        state.key_pairs.sort((a,b)=>{return new Date(b.created_at) - new Date(a.created_at)});
        state.descDate = true
      }
    },
    clearKeys: (state, action) => {
      // Set all state values to their initial values
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    appendKeyPair: (state,action) => {
      if(!state.key_pairs) state.key_pairs = [action.payload]
      else state.key_pairs.unshift(action.payload);
    },
    setKeyPairs: (state,action) => {
      if(action.payload.length === 0) {
        state.key_pairs = null;
      }
      else state.key_pairs = action.payload;
    },
  },
});

export const {setKeyPairs, sortDate, appendKeyPair, clearKeys} = docSlice.actions;
export const selectKeyPairs = (state) => state.key_pairs.key_pairs;

export const getKeyPairs = () => async (dispatch, getState) => {
  try {
    let sg = new SelfGuard(window.storage.api_key, null, null, null, domain);
    let data = await sg.getKeyPairs();
    dispatch(setKeyPairs(data.reverse()));
    return true
  }
  catch(err){
    console.log(err);
    return false;
  }
};

export const createKeyPair = (password) => async (dispatch, getState) => {
  try {
    let sg = new SelfGuard(window.storage.api_key, null, null, null, domain);
    let key_pair = sg.createKeyPair('ecdsa'); //Generate Key Pair
    let encrypted_private_key = encryptWithPassword(key_pair.private_key, password);
    dispatch(appendKeyPair({public_key:key_pair.public_key, encrypted_private_key, created_at: Date.now()}));
    await sg.uploadKeyPair(key_pair, password); //Upload Key Pair
    return true
  }
  catch(err){
    console.log(err);
    throw new Error(err);
  }
}

export default docSlice.reducer;
