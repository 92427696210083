import React,{useState} from "react";

const APIDashboard = () => {
  let api_key = window.storage.api_key;
  let [copyText,setCopy] = useState('Copy');
  
  const copyAPIKey = () => {
    setCopy('Copied');
    var textField = document.createElement('textarea')
    textField.innerText = api_key
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    setTimeout(()=>{
      setCopy('Copy');
    },2000);
  }

  return (
    <div className="border-b border-gray-200 col-span-2 xl:col-span-1 row-span-2 bg-white px-4 py-5 sm:px-6 sm:rounded-lg sm:shadow">

    <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
      <div className="ml-4 mt-2">
        <h3 className="text-lg font-medium leading-6 text-gray-900">API Key: {api_key}</h3>
      </div>
      <div className="ml-4 mt-2 flex-shrink-0">
        <button
          onClick={copyAPIKey}
          type="button"
          className="relative inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700"
        >
          {copyText}
        </button>
      </div>
    </div>
    </div>
  );
};

export default APIDashboard;
