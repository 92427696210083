import moment from 'moment';
import { useSearchParams } from "react-router-dom";
import {useDispatch} from 'react-redux';
import Delete from './delete';
import Edit from './edit';
import {getProfiles, setGroup} from '../../../../../redux/profile';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Group({group,index}) {
  let {notification_group, created_at, id} = group;
  
  //function that takes in a number and returns the respective kb, mb, or gb amount
  let [searchParams,setSearchParams] = useSearchParams();
  let dispatch = useDispatch();

  let cur_group = searchParams.get('group');

  let switchGroup = async () => {
    setSearchParams({group:group.notification_group, dashboard:searchParams.get('dashboard')});
    dispatch(setGroup(group));
    dispatch(getProfiles(group.notification_group))
  }

  let showDate = (date) => {
    //if created at was within the last day just show the time with am/pm, else show the date and time
    if(moment(created_at).isSame(moment(),'day')){
      return moment(created_at).format('hh:mm A')
    }
    else {
      return moment(created_at).format('MM/DD/YY')
    }
  }

  //if cur_group === notification gropu set background of tr to blue
  let background = cur_group === notification_group ? 'bg-slate-300' : 'hover:bg-slate-100 bg-white';
  
  return (
    <tr key={id} onClick={switchGroup} style={{cursor:'pointer'}} className={`${background}`}>
        <td
          className={classNames(
            index === 0 ? '' : 'border-t border-transparent',
            'relative py-4 pl-4 sm:pl-6 pr-3 text-sm'
          )}
        >
        <div className="font-medium text-gray-900 max-w-sm text-truncate">
          {notification_group}
        </div>

        {/* <div className="mt-1 flex flex-col text-gray-500 lg:hidden">
          <span>
            {showDate(created_at)}
          </span>
        </div> */}

        {index !== 0 ? <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" /> : null}

      </td>

      <td
        className={classNames(
          index === 0 ? '' : 'border-t border-gray-200',
          ' px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
        )}
      >
        {showDate(created_at)}
      </td>

      
      <td
        className={classNames(
          index === 0 ? '' : 'border-t border-transparent',
          'relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium'
        )}
      >
        
        <Delete/>
        <Edit/>
        {index !== 0 ? <div className="absolute right-6 left-0 -top-px h-px bg-gray-200" /> : null}
        
      </td>
    </tr>
  )
}
