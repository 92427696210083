import {useSelector} from 'react-redux';
import {updateNotificationGroup,selectGroup, getNotificationGroups} from '../../../../../redux/profile';
import {useDispatch} from 'react-redux';
import Modal from '../../../../helper/modal.js';
import { useState } from "react";
import Toastify from 'toastify-js';
import $ from 'jquery';
import { useSearchParams } from "react-router-dom";

export default function ListFiles() {
  let group = useSelector(selectGroup);
  let dispatch = useDispatch();
  let {notification_group} = group ? group : {};

  const [open, setOpen] = useState(false);
  const [new_notification_group, setCollectionName] = useState('');
  const [new_contract_address, setContractAddress] = useState('');
  const [loading, setLoading] = useState('');
  let [searchParams,setSearchParams] = useSearchParams();

  let showModal = () => {
    setOpen(true);
  }

  async function updateCollection(e){
    if(e && e.preventDefault) e.preventDefault();
    setLoading(true);
    try {
      if(!new_notification_group) {
        Toastify({text:"Collection Name must be set!",style: {background: "linear-gradient(to right, #dc3545, #dc3541"}}).showToast();
        return;
      };
      await dispatch(updateNotificationGroup({old_notification_group: notification_group, contract_address: new_contract_address, notification_group: new_notification_group}))
      Toastify({text:"Group Edited",style: {background: "linear-gradient(to right, #198754, #198751"}}).showToast();
      $('#closeModalEditGroup').click();
      setSearchParams({group:new_notification_group,dashboard:'get_notified'})
      await dispatch(getNotificationGroups(searchParams,setSearchParams));
      setCollectionName('');
      setContractAddress('');
    }
    catch(err){
      Toastify({text:err,style: {background: "linear-gradient(to right, #dc3545, #dc3541"}}).showToast();
    }
    setLoading(false);
    setOpen(false);
  }

  let editfields = [
    {type:'text',value:new_notification_group,label:'Group Name',disabled:false,onChange:setCollectionName},
  ];

  return (
    <>
      <button onClick={showModal} type="button" className="mt-2 lg:mt-0 ml-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Edit</button>
      <Modal open={open} setOpen={setOpen} title={'Edit ' + notification_group} loading={loading} id='editGroup' closeId={'closeModalEditGroup'} fields={editfields} onSubmit={updateCollection}/>
    </>
  )
}

