import Profile from './Profile';
import {useSelector} from 'react-redux';
import {selectProfiles} from '../../../../redux/profile';
import SMS from './Bulk/generateSMS';
import Email from './Bulk/generateEmail';
import Introduction from './Bulk/generateIntro';
import Activate from './Bulk/activateIntro';
import { useSearchParams } from "react-router-dom";

export default function Example() {
  let profiles = useSelector(selectProfiles);
  let [searchParams,] = useSearchParams();

  return (
    <div className="bg-white border border-black/5 xl:row-span-3 xl:col-span-2 rounded-sm">
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mt-4">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-medium text-guard">{searchParams.get('group')}</h1>
          <h2 className="mt-4 text-sm font-medium text-guard">Subscriber Link: 
            <a className='text-self' href={`https://getnotified.xyz/${searchParams.get('group')}`}> {`https://getnotified.xyz/${searchParams.get('group')}`}</a>
          </h2>
        </div>
        <div className='ml-2'>
          <Activate/>
          <Introduction/>
          <Email/>
          <SMS/>
        </div>
      </div>
      <div className="-mx-4 mb-4 mt-4 sm:-mx-6 md:mx-0 md:rounded-lg h-72 overflow-y-scroll">
        <table className="min-w-full divide-y divide-gray-300 mb-4">
          <thead className='bg-white'>
            <tr>
              <th scope="col" className="bg-white sticky top-0 z-10 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                User Id
              </th>
              <th
                scope="col"
                className="bg-white sticky top-0 z-10  hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >  Added
              </th>
              <th
                scope="col"
                className="bg-white sticky top-0 z-10 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >   Last Notification
              </th>
              <th
                scope="col"
                className="bg-white sticky top-0 z-10 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
              </th>
            </tr>
          </thead>
          <tbody className=''>
            {profiles && profiles.length === 0 && 
              <tr className=''>
                <td className='py-4 text-center text-sm text-gray-500' colSpan='4'>Loading...</td>
              </tr>
            }
            {!profiles && 
            <tr>
              <td colSpan='4'>
                <div className="flex items-center" style={{height:'250px',justifyContent:'center'}}>
                  <button
                    id='nodomains'
                    type="button"
                    className="
                    my-auto block m-4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center
                    "
                  >
                    <span className="mt-2 block text-sm font-medium text-gray-900">No Subscribers Added Yet</span>
                  </button>
                </div>
              </td>
            </tr>
            }
            {profiles && profiles.map((profile, index) => (
              <Profile key={profile.id} profile={profile} index={index}/>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  )
}
