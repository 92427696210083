import Navbar from "./Navbar";
import Header from "./header";

import Footer from "./footer";
import Integrations from "./Integrations";
import Notifications from "./Notifications";
import Files from "./Files";
import API from "./API";

export default function Home() {
  return (
    <>
      <div className="max-w-7xl px-4 lg:px-8 mx-auto mb-4">
        <Navbar />
        <Header />
        <Integrations />
        <Notifications />
        <Files />
        <API />
      </div>
      <Footer />
    </>
  );
}
