import Group from './Group';
import {useSelector} from 'react-redux';
import CreateGroup from './createGroup';
import {selectGroups, selectSMSCount, selectEmailCount} from '../../../../redux/profile';
import {selectLimits} from '../../../../redux/usage_limits';

export default function Example() {
  let groups = useSelector(selectGroups);
  let sms_count = useSelector(selectSMSCount);
  let email_count = useSelector(selectEmailCount);
  let usageLimits = useSelector(selectLimits);

  return (
    <div className="bg-white border border-black/5 xl:row-span-3 xl:col-span-2 rounded-sm">
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mt-4">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-medium text-guard">Your Notification Groups</h1>
          {usageLimits && 
          <>
          <h2 className="text-sm font-medium text-guard" style={{color:'grey'}}>Monthly SMS: {sms_count} / {usageLimits.sms_notifications}</h2>
          <h2 className="text-sm font-medium text-guard" style={{color:'grey'}} >Monthly Email: {email_count} / {usageLimits.email_notifications}</h2>
          </>
          }
        </div>
        <div className="">
          <CreateGroup />
        </div>
      </div>
      <div className="-mx-4 mb-4 mt-4 sm:-mx-6 md:mx-0 md:rounded-lg h-72 overflow-y-scroll">
        <table className="min-w-full divide-y divide-gray-300 mb-4">
          <thead className='bg-white'>
            <tr>
              <th scope="col" className="bg-white sticky top-0 z-10 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Name
              </th>
              <th scope="col" className="bg-white sticky top-0 z-10 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Added
              </th>
              <th scope="col" className="bg-white sticky top-0 z-10 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                
              </th>
            </tr>
          </thead>
          <tbody className=''>
            {groups && groups.length === 0 && 
              <tr className=''>
                <td className='py-4 text-center text-sm text-gray-500' colSpan='4'>Loading...</td>
              </tr>
            }
            {!groups && 
            <tr>
              <td colSpan='2'>
                <div className="flex items-center" style={{height:'250px',justifyContent:'center'}}>
                  <button
                    id='nodomains'
                    type="button"
                    className="
                    my-auto block m-4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center
                    "
                  >
                    <span className="mt-2 block text-sm font-medium text-gray-900">No Groups Added Yet</span>
                  </button>
                </div>
              </td>
            </tr>
            }
            {groups && groups.map((group, index) => (
              <Group key={group.id} group={group} index={index}/>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  )
}
