import {useSelector} from 'react-redux';
import {selectGroup, getNotificationGroups} from '../../../../../redux/profile';
import { useSearchParams } from "react-router-dom";
import SelfGuard from 'selfguard-client';
import {useDispatch} from 'react-redux';
import {useState} from 'react';

let domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080"

export default function ActivateIntro() {
  let group = useSelector(selectGroup);
  let [searchParams,setSearchParams] = useSearchParams();
  let dispatch = useDispatch();
  let [loading, setLoading] = useState(false);
  
  let activateIntroductionMessage = async() => {
    if(loading) return;
    setLoading(true);
    let sg = new SelfGuard(window.storage.api_key, null, null, null, domain);
    await sg.activateIntroductionMessage({notification_group:group.notification_group,activate:!group.introduction_activated});
    await dispatch(getNotificationGroups(searchParams,setSearchParams));
    setLoading(false);
  }

  return group !== null ? (
    <>
    {loading ?
      <button type="button" className="disabled mt-4 mr-2 lg:mt-0 relative inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700">{group.introduction_activated ? 'Deactivating' : 'Activating'} Introduction Message...</button>
      :
      <button onClick={activateIntroductionMessage} type="button" className="mt-4 mr-2 lg:mt-0 relative inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700">{group.introduction_activated ? 'Deactivate' : 'Activate'} Introduction Message</button>
    }
    </>
  ) : null;
}

