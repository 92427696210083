import {useEffect, useState} from 'react'
import {isLoggedIn} from '../../api/getJWT';
import { signOut } from "supertokens-auth-react/recipe/emailpassword";

export default function Home(props) {
  let [called,setCalled] = useState(false);

  useEffect(()=>{
    async function fetchData() {
      try {
        let loggedIn = await isLoggedIn();
        console.group({loggedIn});
        setCalled(loggedIn);
      }
      catch(err){
        window.storage.removeItem('api_key');
        window.storage.removeItem('public_key');
        window.storage.removeItem('private_key');
        await signOut();
        window.location.href = '/';
      }
    }
    fetchData();
  },[])

  return called && props.children
}
