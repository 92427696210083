import React, { useState } from "react";
import $ from 'jquery';
import Toastify from 'toastify-js'
import {useDispatch} from 'react-redux';
import {createDomain} from '../../../../redux/domain';
import Modal from '../../../helper/modal.js';

export default function GenerateDomain() {
  const dispatch = useDispatch();
  const [domain, setDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  let showModal = () => {
    setOpen(true);
  }

  async function generate(e){
    if(e && e.preventDefault) e.preventDefault();
    setLoading(true);
    await dispatch(createDomain(domain))
    Toastify({text:"API Domain Created",style: {background: "linear-gradient(to right, #198754, #198751"}}).showToast();
    $('#closeModalDomain').click();
    setDomain('');
    setOpen(false);
    setLoading(false);
  }

  let fields = [{type:'text',value:domain,label:'Domain',disabled:false,onChange:setDomain}];

  return (
    <>
      <Modal open={open} setOpen={setOpen} title={'Add Domain'} loading={loading} id='generateDomains' closeId={'closeModalDomain'} fields={fields} onSubmit={generate}/>
      <button onClick={showModal} type="button" className="relative inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700">Add Domain</button>
    </>
  )
}

