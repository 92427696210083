import Navbar from "../Landing/Navbar";
// import * as Select from "@radix-ui/react-select";
// import { IoIosArrowDown, IoIosCheckmark } from "react-icons/io";

export default function Pricing() {
  return (
    <>
      <div className="max-w-7xl px-4 lg:px-8 mx-auto">
        <Navbar />
        <div className="flex mt-2  w-full">
          <div className="flex flex-col">
            <h2 className="font-medium text-guard">Pricing </h2>
            <p className="text-guard/50 mt-4">
              Coming Soon...
            </p>
            <div>
              <div className="flex mt-32 mb-72">
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <div className="flex flex-col">
                      {/* <h3 className="font-medium text-guard">Coming Soon</h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-full flex justify-end">
            <Select.Root defaultValue="api">
              <Select.Trigger asChild>
                <button className="inline-flex h-16 w-48 px-4 items-center justify-between space-x-4 overflow-hidden  rounded-md border-[1px] border-black/10 bg-midnight py-2 text-self placeholder-white/50">
                  <Select.Value />
                  <Select.Icon>
                    <IoIosArrowDown className="text-self group-hover:text-white" />
                  </Select.Icon>
                </button>
              </Select.Trigger>

              <Select.Content>
                <div className="overflow-hidden rounded-md bg-white shadow-nav text-self border border-black/10">
                  <Select.ScrollUpButton />
                  <Select.Viewport>
                    <div className="">
                      <Select.Item value="api" asChild>
                        <div className="unset  relative m-1 focus:bg-self group flex cursor-pointer items-center justify-between rounded-md px-3 py-4 ">
                          <Select.ItemText>
                            <p className="text-self group-focus:text-white">
                              SelfGuard API
                            </p>
                          </Select.ItemText>
                          <Select.ItemIndicator>
                            <IoIosCheckmark className="text-lg text-self" />
                          </Select.ItemIndicator>
                        </div>
                      </Select.Item>
                      <Select.Item value="file" asChild>
                        <div className="unset  relative m-1 focus:bg-self group flex cursor-pointer items-center justify-between rounded-md px-3 py-4 ">
                          <Select.ItemText>
                            <p className="text-self group-focus:text-white">
                              FilePeer
                            </p>
                          </Select.ItemText>
                          <Select.ItemIndicator>
                            <IoIosCheckmark className="text-lg text-self" />
                          </Select.ItemIndicator>
                        </div>
                      </Select.Item>
                      <Select.Item value="notifications" asChild>
                        <div className="unset  relative m-1 focus:bg-self group flex cursor-pointer items-center justify-between rounded-md px-3 py-4 ">
                          <Select.ItemText>
                            <p className="text-self group-focus:text-white">
                              GetNotified
                            </p>
                          </Select.ItemText>
                          <Select.ItemIndicator>
                            <IoIosCheckmark className="text-lg text-self" />
                          </Select.ItemIndicator>
                        </div>
                      </Select.Item>
                    </div>
                  </Select.Viewport>

                  <Select.ScrollDownButton />
                </div>
              </Select.Content>
            </Select.Root>
          </div> */}
        </div>
        {/* <div className="w-full mb-32 mt-16 lg:space-x-8 lg:space-y-0 space-y-8 lg:flex-row flex-col flex">
          <div className="w-full bg-white border border-black/10 items-center p-8 rounded-lg flex flex-col space-y-8">
            <svg
              viewBox="0 0 22 22"
              fill="none"
              className="w-8 stroke-self"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 9C8.5 9.27614 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.27614 7.5 9M8.5 9C8.5 8.72386 8.27614 8.5 8 8.5C7.72386 8.5 7.5 8.72386 7.5 9M8.5 9H7.5M14.5 9C14.5 9.27614 14.2761 9.5 14 9.5C13.7239 9.5 13.5 9.27614 13.5 9M14.5 9C14.5 8.72386 14.2761 8.5 14 8.5C13.7239 8.5 13.5 8.72386 13.5 9M14.5 9H13.5M15.0002 14C14.088 15.2144 12.6357 16 10.9999 16C9.36406 16 7.91172 15.2144 6.99951 14M11 21V21C16.5228 21 21 16.5228 21 11V11C21 5.47715 16.5228 1 11 1V1C5.47715 1 1 5.47715 1 11V11C1 16.5228 5.47715 21 11 21Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="flex flex-col items-center">
              <p className="text-guard text-xl font-medium">Free Plan</p>
              <p className="text-guard/50 mt-2">For the builders</p>
            </div>
            <p className="text-guard text-4xl">
              $0.00<span className="text-xl text-guard/50">/mo</span>
            </p>
            <div className="w-full h-px bg-black/10" />
            <div className="flex items-center">
              <svg
                viewBox="0 0 12 13"
                fill="none"
                className="w-4 mr-2 stroke-self"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 6L6 7.5L10.0002 3.49979M11 2.5L10.0002 3.49979M10.0002 3.49979C9.08799 2.28546 7.63572 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 6.15753 10.9656 5.82311 10.9 5.5"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-guard">100 data tokenizations</p>
            </div>
            <div className="flex items-center">
              <svg
                viewBox="0 0 12 13"
                fill="none"
                className="w-4 mr-2 stroke-self"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 6L6 7.5L10.0002 3.49979M11 2.5L10.0002 3.49979M10.0002 3.49979C9.08799 2.28546 7.63572 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 6.15753 10.9656 5.82311 10.9 5.5"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-guard">100 data tokenizations</p>
            </div>
            <button className="h-20 rounded-lg bg-guard text-white w-full">
              Get Access Now
            </button>
          </div>
          <div className="w-full pricing-gradient border border-black/10 items-center p-8 rounded-lg flex flex-col space-y-8">
            <svg
              viewBox="0 0 22 22"
              fill="none"
              className="w-8 stroke-white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 9C8.5 9.27614 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.27614 7.5 9M8.5 9C8.5 8.72386 8.27614 8.5 8 8.5C7.72386 8.5 7.5 8.72386 7.5 9M8.5 9H7.5M14.5 9C14.5 9.27614 14.2761 9.5 14 9.5C13.7239 9.5 13.5 9.27614 13.5 9M14.5 9C14.5 8.72386 14.2761 8.5 14 8.5C13.7239 8.5 13.5 8.72386 13.5 9M14.5 9H13.5M15.0002 14C14.088 15.2144 12.6357 16 10.9999 16C9.36406 16 7.91172 15.2144 6.99951 14M11 21V21C16.5228 21 21 16.5228 21 11V11C21 5.47715 16.5228 1 11 1V1C5.47715 1 1 5.47715 1 11V11C1 16.5228 5.47715 21 11 21Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="flex flex-col items-center">
              <p className="text-white text-xl font-medium">Premium Plan</p>
              <p className="text-white/50 mt-2">For the builders</p>
            </div>
            <p className="text-white text-4xl">
              $100.00<span className="text-xl text-white/50">/mo</span>
            </p>
            <div className="w-full h-px bg-white/10" />
            <div className="flex items-center">
              <svg
                viewBox="0 0 12 13"
                fill="none"
                className="w-4 mr-2 stroke-white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 6L6 7.5L10.0002 3.49979M11 2.5L10.0002 3.49979M10.0002 3.49979C9.08799 2.28546 7.63572 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 6.15753 10.9656 5.82311 10.9 5.5"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-white">100 data tokenizations</p>
            </div>
            <div className="flex items-center">
              <svg
                viewBox="0 0 12 13"
                fill="none"
                className="w-4 mr-2 stroke-white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 6L6 7.5L10.0002 3.49979M11 2.5L10.0002 3.49979M10.0002 3.49979C9.08799 2.28546 7.63572 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 6.15753 10.9656 5.82311 10.9 5.5"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-white">100 data tokenizations</p>
            </div>
            <button className="h-20 rounded-lg bg-white/10 text-white w-full">
              Get Access Now
            </button>
          </div>
          <div className="w-full bg-white border border-black/10 items-center p-8 rounded-lg flex flex-col space-y-8">
            <svg
              viewBox="0 0 22 22"
              fill="none"
              className="w-8 stroke-self"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 9C8.5 9.27614 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.27614 7.5 9M8.5 9C8.5 8.72386 8.27614 8.5 8 8.5C7.72386 8.5 7.5 8.72386 7.5 9M8.5 9H7.5M14.5 9C14.5 9.27614 14.2761 9.5 14 9.5C13.7239 9.5 13.5 9.27614 13.5 9M14.5 9C14.5 8.72386 14.2761 8.5 14 8.5C13.7239 8.5 13.5 8.72386 13.5 9M14.5 9H13.5M15.0002 14C14.088 15.2144 12.6357 16 10.9999 16C9.36406 16 7.91172 15.2144 6.99951 14M11 21V21C16.5228 21 21 16.5228 21 11V11C21 5.47715 16.5228 1 11 1V1C5.47715 1 1 5.47715 1 11V11C1 16.5228 5.47715 21 11 21Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div className="flex flex-col items-center">
              <p className="text-guard text-xl font-medium">Pro Plan</p>
              <p className="text-guard/50 mt-2">For the builders</p>
            </div>
            <p className="text-guard text-4xl">
              $1,000.00<span className="text-xl text-guard/50">/mo</span>
            </p>
            <div className="w-full h-px bg-black/10" />
            <div className="flex items-center">
              <svg
                viewBox="0 0 12 13"
                fill="none"
                className="w-4 mr-2 stroke-self"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 6L6 7.5L10.0002 3.49979M11 2.5L10.0002 3.49979M10.0002 3.49979C9.08799 2.28546 7.63572 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 6.15753 10.9656 5.82311 10.9 5.5"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-guard">100 data tokenizations</p>
            </div>
            <div className="flex items-center">
              <svg
                viewBox="0 0 12 13"
                fill="none"
                className="w-4 mr-2 stroke-self"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5 6L6 7.5L10.0002 3.49979M11 2.5L10.0002 3.49979M10.0002 3.49979C9.08799 2.28546 7.63572 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 6.15753 10.9656 5.82311 10.9 5.5"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-guard">100 data tokenizations</p>
            </div>
            <button className="h-20 rounded-lg bg-guard text-white w-full">
              Get Access Now
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
}
