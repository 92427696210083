import React from "react";
import { useNavigate } from 'react-router-dom';

const Header = () => {

  const navigate = useNavigate();

  function handleClick() {
    navigate(`/auth?redirectToPath=/home`);
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <h2 className="text-center font-medium text-guard mt-16">
        Encrypt <span className="text-self">everything.</span>
        <br />
        Easily.
      </h2>
      <p className="text-guard/50 mt-8 text-center">
        SelfGuard provides <b>encryption APIs and modules</b> to <br />
        allow developers to build better and more secure user experiences
      </p>
      <button onClick={handleClick} className="bg-self h-16 px-16 rounded-md text-white mt-8 hover:bg-self/50">
        Sign Up
      </button>
      <img
        src="header-image.png"
        alt=""
        className="max-w-l object-cover mt-16 -mb-64"
      />
    </div>
  );
};

export default Header;
