import moment from 'moment';
import Email from './email';
import SMS from './sms';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Profile({profile, index}) {
  let {address, created_at, email_activated, phone_activated, notification_events} = profile;

  let showDate = (date) => {
    //if created at was within the last day just show the time with am/pm, else show the date and time
    if(moment(date).isSame(moment(),'day')){
      return moment(date).format('hh:mm A')
    }
    else {
      return moment(date).format('MM/DD/YY')
    }
  }

  return (
    <tr key={address}>
        <td
          className={classNames(
            index === 0 ? '' : 'border-t border-transparent',
            'relative py-4 pl-4 sm:pl-6 pr-3 text-sm'
          )}
        >
        <div className="font-medium text-gray-900 max-w-sm text-truncate">
          {address}
        </div>
        
        <div className="mt-1 flex flex-col text-gray-500 lg:hidden">
          <span>
            {showDate(created_at)}
          </span>
          <span className="hidden sm:inline"> </span>
          <span>{notification_events.length === 1 ? showDate(notification_events[0].created_at) : ''}
          </span>
        </div>

        {index !== 0 ? <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" /> : null}

      </td>
      <td
        className={classNames(
          index === 0 ? '' : 'border-t border-gray-200',
          'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
        )}
      >
        {showDate(created_at)}
      </td>
      <td
        className={classNames(
          index === 0 ? '' : 'border-t border-gray-200',
          'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
        )}
      >
        {notification_events.length === 1 ? showDate(notification_events[0].created_at) : ''}
      </td>
      <td
        className={classNames(
          index === 0 ? '' : 'border-t border-transparent',
          'relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium'
        )}
      >
        {email_activated &&
          <Email to={address}/>
        }
        {phone_activated &&
          <SMS to={address}/>
        }

        {index !== 0 ? <div className="absolute right-6 left-0 -top-px h-px bg-gray-200" /> : null}
        
      </td>
    </tr>
  )
}
