import React from "react";

const Files = () => {
  return (
    <div className="w-full mt-48 flex justify-center flex-col items-center">
      <div className="flex flex-col lg:flex-row items-center lg:items-start w-full items-center">
        <div className="lg:w-1/2 w-full flex flex-col lg:pr-24">
          <h2 className="text-self">File Peer</h2>
          <h3 className="text-guard font-medium mt-4" style={{lineHeight:'60px'}}>
            Encrypt, upload and transfer files
          </h3>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 mt-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
          </svg>

          <p className="text-guard text-2xl mt-4">Upload & Download Files</p>
          <p className="text-guard/50 leading-relaxed mt-4">
            When you upload a file, it is automatically encrypted and stored in a manner that can only be viewed by you.
          </p>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 mt-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
          </svg>

          <p className="text-guard text-2xl mt-4">Share Files</p>
          <p className="text-guard/50 leading-relaxed mt-4">
            When you share a file, the file encryption key is stored again in a manner that can only be retrieved by the receiver. 
          </p>
        </div>
        <div className="lg:w-1/2 mt-12 lg:mt-0 flex flex-col w-full bg-pale flex items-center">
          <img
            src="peer.png"
            alt="alt"
            style={{height:'100%'}}
          />
          <div className="flex p-8 w-full bg-self">
            <a href="https://filepeer.xyz/" target="_blank" rel="noreferrer" className="w-full">
              <div className="flex">
                <p className="text-white mr-4">Visit FilePeer</p>
                <img src="/white-arrow.svg" alt="" className="w-4" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Files;
