import React, { useState } from "react";
import $ from 'jquery';
import SelfGuard from 'selfguard-client';
import Toastify from 'toastify-js'
import Modal from '../../../../helper/modal.js';
import {selectGroup, getNotificationCount, getProfiles,selectEmailCount} from '../../../../../redux/profile';
import {selectLimits} from '../../../../../redux/usage_limits';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import { useSearchParams } from "react-router-dom";

let domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080"

export default function Home({to}) {
  let group = useSelector(selectGroup);
  const [text, setText] = useState('');
  const [subject, setSubject] = useState('');
  let [loading, setLoading] = useState(false);
  let dispatch = useDispatch();
  let [searchParams,] = useSearchParams();
  const [open, setOpen] = useState(false);

  let email_count = useSelector(selectEmailCount);
  let usageLimits = useSelector(selectLimits);

  async function sendEmail(e){
    if(loading) return;
    try {
      if(e && e.preventDefault) e.preventDefault();
      setLoading(true);
      let sg = new SelfGuard(window.storage.api_key, null, null, null, domain);
      await sg.sendEmail({user_address:to,notification_group:group.notification_group, subject, body:text});
      Toastify({text:"Email Sent to " + to,style: {background: "linear-gradient(to right, #198754, #198751"}}).showToast();
      $('#closeModalEmail').click();
      setText('');
      setSubject('');
      dispatch(getNotificationCount());
      dispatch(getProfiles(searchParams.get('group')))
    }
    catch(err){
      console.log(err);
    }
    setLoading(false);
    setOpen(false);
  }


  let showModal = () => {
    setOpen(true);
  }

  let fields = [{type:'text',value:to,label:'To',disabled:true},
    {onChange:setSubject,type:'text',value:subject,label:'Subject',disabled:false},
    {onChange:setText,type:'text',value:text,label:'Body',disabled:false}
  ];

  return (
    <>
    <button onClick={showModal}  disabled={usageLimits.email_notifications < email_count} type="button" className="mt-2 lg:mt-0 ml-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Email</button>
    <Modal open={open} setOpen={setOpen} title={'Send Email'} loading={loading} id='sendEmail2' closeId={'closeModalEmail'} fields={fields} onSubmit={sendEmail}/>
    </>
  )
}

