import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";

Session.addAxiosInterceptors(axios);

let url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:8080';

async function createAPIDomain(domain){
  try {
    let result = await axios.post(url + "/createAPIDomain",{data:{api_key:window.storage.api_key,domain }});
    return result.data;
  }
  catch(err){
    console.log({err});
  }
}

async function deleteAPIDomain(domain){
  try {
    let result = await axios.post(url + "/deleteAPIDomain",{data:{api_key:window.storage.api_key,domain }});
    return result.data;
  }
  catch(err){
    console.log({err});
  }
}

async function retrieveAPIDomains(){
  try {
    let result = await axios.post(url + "/retrieveAPIDomains",{data:{api_key:window.storage.api_key}});
    return result.data;
  }
  catch(err){
    console.log({err});
  }
}

const initialState = {
  domains: [],
  descDate: true,
};

export const docSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    sortDate: (state) => {
      if(state.descDate){
        state.domains.sort((a,b)=>{return new Date(a.created_at) - new Date(b.created_at)});
        state.descDate = false;
      }
      else {
        state.domains.sort((a,b)=>{return new Date(b.created_at) - new Date(a.created_at)});
        state.descDate = true
      }
    },
    appendDomain: (state,action) => {
      if(!state.domains) state.domains = [action.payload]
      else state.domains.unshift(action.payload);
    },
    clearDomains: (state, action) => {
      // Set all state values to their initial values
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    setDomains: (state,action) => {
      if(action.payload.length === 0) {
        state.domains = null;
      }
      else state.domains = action.payload;
    },
  },
});

export const {setDomains, sortDate, appendDomain, clearDomains} = docSlice.actions;
export const selectDomains = (state) => state.domains.domains;

export const getDomains = () => async (dispatch, getState) => {
  try {
    let data = await retrieveAPIDomains();
    dispatch(setDomains(data.reverse()));
    return true
  }
  catch(err){
    console.log(err);
    return false;
  }
};

export const deleteDomain = (domain) => async (dispatch, getState) => {
  try {
    await deleteAPIDomain(domain); //Upload Key Pair
    let data = await retrieveAPIDomains();
    dispatch(setDomains(data.reverse()));
    return true
  }
  catch(err){
    console.log(err);
    throw new Error(err);
  }
}

export const createDomain = (domain) => async (dispatch, getState) => {
  try {
    await createAPIDomain(domain); //Upload Key Pair
    dispatch(appendDomain({domain:domain, created_at: Date.now()}));
    return true
  }
  catch(err){
    console.log(err);
    throw new Error(err);
  }
}

export default docSlice.reducer;
