import React, { useState } from "react";
import {useDispatch} from 'react-redux';
import Toastify from 'toastify-js';
import $ from 'jquery';
import {createNotificationGroup,getNotificationGroups} from '../../../../redux/profile';
import Modal from '../../../helper/modal.js';
import { useSearchParams } from "react-router-dom";

export default function ListFiles(props) {
  let dispatch = useDispatch();
  const [notification_group, setCollectionName] = useState('');
  const [contract_address, setContractAddress] = useState('');
  let [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  let [searchParams,setSearchParams] = useSearchParams();

  async function generate(e){
    if(e && e.preventDefault) e.preventDefault();
    setLoading(true);
    try {
      if(!notification_group) {
        Toastify({text:"Notification Group Name must be set!",style: {background: "linear-gradient(to right, #dc3545, #dc3541"}}).showToast();
        return;
      };
      await dispatch(createNotificationGroup({contract_address, notification_group}))
      await dispatch(getNotificationGroups(searchParams,setSearchParams));

      Toastify({text:"Group Created",style: {background: "linear-gradient(to right, #198754, #198751"}}).showToast();
      $('#closeModalCreateGroup').click();
      setSearchParams({group:notification_group, dashboard:'get_notified'})
      setCollectionName('');
      setContractAddress('');
    }
    catch(err){
      Toastify({text:err,style: {background: "linear-gradient(to right, #dc3545, #dc3541"}}).showToast();
    }
    setLoading(false);
    setOpen(false);
  }

  let showModal = () => {
    setOpen(true);
  }

  let fields = [
    {type:'text',value:notification_group,label:'Group Name',disabled:false,onChange:setCollectionName},
  ];

  return (
    <>
      <button onClick={showModal} type="button" className="mt-4 lg:mt-0 relative inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700">Create Group</button>
      <Modal open={open} setOpen={setOpen} title={'Add Group'} loading={loading} id='generateGroup' closeId={'closeModalCreateGroup'} fields={fields} onSubmit={generate}/>
    </>
  )
}

